* {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
ul, ol, li{
  list-style-type: none;
  padding: 0px;
}
ul, ol{
  padding-left: 0px !important;
}
img{
  display: table-cell;
  max-width: 100%;
}
.listitem-ul{
  margin-left: 0px !important;
  }
.listitem-ul .liitm{
  font-size: 14px;
  font-weight: 600;
  padding: 3px 0px 3px 20px;
  width: 100%;
  position: relative;
}
.listitem-ul .liitm::before{
  content: "";
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 12px;
}
.space-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner-listone{
  list-style-type: disc;
}

.hrfPointer{
  list-style-type: disc;
  margin-left: 25px;
}

.container {
  width: 1140px;
  margin: 0px auto;
  max-width: 90%;
  position: relative;
}

.banner-section {
  overflow: visible;
  background-image: url("../../../public/assets/images/top-banner.png");
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  padding:80px 0px;
  position: relative;
  min-height: 823px;
  background-position: 50% 50%;
}

.contenttxt{
  text-align: justify;
}


.gallery-img .list-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 576px) {
  .banner-section {
    background-position: 100% 0%;
  }
}

.banner-section .container {
  min-height: 823px;
}

.banner-section h1 {
  font-size: 3.3rem;
  padding: 124px 0px 0 0;
  font-weight: 700;
  /* width: 776px; */
  max-width: 100%;
  color: white;
  line-height: 1.1;
}

@media screen and (max-width: 992px) {
  .banner-section h1 {
    padding-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .banner-section h1 {
    font-size: 3.75rem;
    padding-top: 53px;
  }
}

@media screen and (max-width: 576px) {
  .banner-section h1 {
    font-size: 2.25rem;
  }
}

.banner-section h3 {
  font-size: 1.25rem;
  letter-spacing: 0px;
  text-transform: none;
  line-height: 1.4;
  font-weight: 400;
  width: 532px;
  max-width: 100%;
  margin: 360px 0 -55px auto;
}

@media screen and (max-width: 767px) {
  .banner-section h3 {
    margin-top: 276px;
    margin-left: 8px;
  }
}

.four-points {
  padding: 100px 0px;
}

.four-points ul {
  margin: -20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.four-points li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 50%;
  padding: 20px;
}

@media screen and (max-width: 992px) {
  .four-points li {
    width: 100%;
  }
}

.four-points li .blue-box {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-color: #48a4d6;
}

.four-points li .info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #333;
  line-height: 1.6;
  font-size: 1rem;
}

.about-us {
  background-color: #48a4d6;
  padding: 100px 0px;
}

.about-us h2{
  font-weight: 700;
  font-size: 4.5rem;
  width: 992px;
  max-width: 100%;
}

.about-us .container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white;
}

.about-us .container .left-portion {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .about-us .container .left-portion {
    width: 100%;
  }
}

.about-us .container .left-portion h3 {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300;
}

.about-us .container .left-portion h1 {
  font-weight: 700;
  font-size: 7.5rem;
  margin: 20px 1px 0;
}

.about-us .container .left-portion h2 {
  font-weight: 700;
  font-size: 2.25rem;
  margin: 30px 1px 0;
}

.about-us .container .left-portion button {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;
  border-radius: 30px;
  letter-spacing: 1px;
  background-image: none;
  margin: 38px auto 0 1px;
  padding: 15px 61px 17px 60px;
}

.about-us .container .right-portion {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .about-us .container .right-portion {
    width: 100%;
    margin-top: 30px;
  }
}

.about-us .container .right-portion h4 {
  font-size: 1.25rem;
  margin: 0;
  line-height: 32px;
  font-weight: 500;
}

.about-us .container .right-portion img {
  width: 117px;
  border-radius: 50%;
  height: 117px;
  object-fit: cover;
  margin-top: 30px;
}

.about-us .container .right-portion p {
  margin-top: 30px;
  font-size: 16px;
}

@media screen and (max-width: 1200px) {

  .about-us h2{
    
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 992px) {

  .about-us{
    padding: 100px 0px;
  }
  .about-us h2 {    
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
 
  .about-us h2 {
    font-size: 2.75rem;
  }
  .about-us{
    padding: 100px 0px;
  }
}

.building {
  background-image: url("../../../public/assets/images/building.jpg");
  overflow: visible;
  object-fit: cover;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 680px;
  background-position: 50% 50%;
  padding:80px 0px;
}

.building h2{
  font-weight: 700;
  font-size: 4.5rem;
  width: 992px;
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  .building {
    min-height: 560px;
    
  }
  .building h2{
    
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 992px) {
  .building {
    min-height: 430px;
    
  }
  .building h2 {
    
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .building {
    min-height: 322px;
    
  }
  .building h2 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 1200px) {
  .building {
    min-height: 560px;
  }
}

.building .building-content {
  width: 800px;
  max-width: 90%;
  padding: 10px 30px;
  border-radius: 20px;
  background-color: white;
  color: black;
}


.building .building-content .row-1 .galleryy-img{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px -10px;
}

.building .building-content .row-1 .galleryy-img .list-img{
  /* width: 110px;
  height: 100px; */
  object-fit: cover;
  margin: 10px;
  width: 13%;
}

@media screen and (max-width: 576px) {
  .building .building-content .row-1 .galleryy-img .list-img {
    width: calc(100% / 4 - 20px);
    max-height: 25vw;
    margin: 5px 10px;
  }
  .building-content h2{
    font-size: 32px;
  }
}
@media screen and (max-width: 430px) {
  .building .building-content .row-1 .galleryy-img .list-img {
    width: calc(100% / 3 - 20px);
    max-height: 22vw;
  }
}

@media screen and (max-width: 360px) {
  .building .building-content .row-1 .galleryy-img .list-img {
    /* width: 20vw; */
    max-height: 20vw;
  }
}

/* .building .building-content .row-1{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
} */

/* .building .building-content .row-1 div{
  width: 50%;
}
.building .building-content .row-1 div:last-child{
  padding-left: 40px;
} */
/* 
.building .building-content .row-1 h3{
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0px;
}
.building .building-content .row-1 ul{
  list-style-type: disc;
  padding-left: 20px;
}
.building .building-content .row-1 li{
  list-style-type: disc;
  padding: 3px 0px;
  font-size: 13px;
} */

@media screen and (max-width: 767px) {
  .about-us{
    padding: 50px 0px;
  }
  .four-points{
    padding: 50px 0px;
  }
  .four-points li .info{
    width: 100%;
    flex: auto;
    margin-top: 20px;
  }
  .building .building-content .row-1 div{
    width: 100%;
  }
  .building .building-content .row-1 div:last-child{
    width: calc(100% + 20px);
    padding-left: 0px;
  }
  .building .building-content .row-1 li{
    font-size: 12px;
  }
  .building .building-content .view-more-cover a{
    font-size: 12px;
  }
}

.program {
  padding: 80px 0px;
}

.program h3 {
  font-weight: 700;
  font-size: 4.5rem;
  width: 992px;
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  .program h3 {
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 992px) {
  .program h3 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 576px) {
  .program h3 {
    font-size: 2.25rem;
  }
}

.program h3 span {
  color: #48a4d6;
}

.program ul {
  width: 100%;
  margin-left: auto;
  margin-top: 50px;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.program ul li {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.program ul li:not(:last-child) {
  margin-bottom: 80px;
}

.program ul li .blue-box {
  width: 102px;
  height: 91px;
  color: white;
  margin-right: 30px;
  background-color: #48a4d6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 768px){
  .program ul li {
    flex-direction: column;
  }
  .program ul li .blue-box {
    width: 82px;
    height: 73px;
    font-size: 2rem;
    margin-right: 0px;
  }
}

.program ul li .info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 20px;
  color: #111111;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .program ul li{
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .program ul li .info {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    margin-top: 20px;
  }
}


@media screen and (max-width: 1200px) {

  .carousel h2{    
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 992px) {
  
  .carousel h2 {    
    font-size: 3rem;
  }
}

.carousel .awssld__content{
  background-color: white;
}
.carousel {
  padding: 85px 0px;
  background-color: #f2f2f2;
}
.carousel h2{
  font-weight: 700;
  font-size: 4.5rem;
  width: 992px;
  max-width: 100%;
}
.carousel .carousel-slide{
  padding: 8%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media screen and (max-width: 768px) {
  .carousel .carousel-slide{
    flex-direction: column;
  }
  .carousel h2 {    
    font-size: 2.25rem;
  }
}
.carousel .carousel-slide a{
  font-weight: bold;
  font-size: 20px;
}
.carousel .carousel-slide ul{
  list-style-type: disc;
  padding-left: 20px;
}
.carousel .carousel-slide ul li{
  list-style-type: disc;
}

.signature {
  padding: 80px 0px;
}

.signature .signature-content {
  width: 100%;
  display: flex;
  max-width: 100%;
}

.signature .signature-content h2 {
  font-weight: 700;
  font-size: 2.25rem;
  color: #48a4d6;
}

.signature .signature-content h4 {
  font-weight: 400;
  font-size: 1.25rem;
  margin-top: 10px;
}

.signature .signature-content img {
  width: 255px;
  height: 124px;
  margin: 35px 0px 30px;
}

.signature .signature-content p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2;
}

.contact-us {
  padding: 80px 0px;
  background-color: #f2f2f2;
}

.contact-us .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.contact-us .container .left-portion {
  width: 450px;
  text-align: right;
  max-width: 100%;
  padding-right: 30px;
}

@media screen and (max-width: 768px) {
  .contact-us .container .left-portion {
    width: 100%;
    padding: 0px;
  }
}

.contact-us .container .left-portion h2 {
  font-weight: 700;
  font-size: 2.25rem;
  margin-bottom: 30px;
}

.contact-us .container .left-portion p {
  line-height: 1.6;
  font-weight: 500;
}

.contact-us .container .right-portion {
  width: 450px;
  max-width: 100%;
  padding-left: 30px;
}

@media screen and (max-width: 768px) {
  .contact-us .container .right-portion {
    width: 100%;
    padding: 0px;
    margin-top: 40px;
  }
}

.contact-us .container .right-portion h4 {
  font-size: 1.25rem;
  line-height: 1.6;
  font-weight: 400;
}

.contact-us .container .right-portion h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 1.875rem;
  color: #48a4d6;
}

.contact-us .container .right-portion h3 {
  font-weight: 400;
  font-size: 1.875rem;
}

footer {
  color: white;
  background-color: #333333;
  text-align: center;
  padding: 100px 0px;
}