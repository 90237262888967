.detail-container{
  max-width:100%;
  padding:10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.detail-container .img{
  width: 254px;
    max-width: 100%;

}
.detail-container .text{
  text-align: justify;
  flex: 1;
  padding-left: 30px;
}

@media screen and (max-width: 991px){
  .detail-container .img{
    margin: auto;
  }
  .detail-container .text{
    width: 100%;
    flex: auto;
    padding-top: 30px;
    padding-left: 0px;
  }
}

.carousel .awssld__content {
  background-color: white;
  height: 340px !important;
}

.awssld--organic-arrows {
  background-color: white;
  height: 340px !important;
}
.gallery-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px -10px;
}

.gallery-img .list-img {
  width: 110px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
}

.outer-list{
  list-style-type: disc;
  text-decoration: underline;
}
.inner-list{
  list-style-type: circle;
}

.inner-listone{
  list-style-type: disc;
}
.inner-ul{
  margin-left: 20px;
}


.contenttxt{
  text-align: justify;
  margin-right: 20px;
}
.img-text {
  display: flex;
}
.img-text .imgsection{
  width: 100%;
  padding: 10px;
}
.img-text .txtsection{
  width: 650%;
}


@media screen and (max-width: 576px) {
  .gallery-img .list-img {
    width: 25vw;
    height: 25vw;
  }
}
@media screen and (max-width: 430px) {
  .gallery-img .list-img {
    width: 24vw;
    height: 24vw;
  }
}
@media screen and (max-width: 360px) {
  .gallery-img .list-img {
    width: 23vw;
    height: 23vw;
  }
}
.gallery-img .list-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
