.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 4px 6%;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  position: sticky;
  z-index: 99;
  top: 0;
}

.page-header .logo-cover {
  width: 180px;
}

.page-header .logo-cover img {
  max-width: 100%;
  max-height: 100%;
  display: table-cell;
}
.page-header .hamburger-cover img {
  width: 40px;
}

.page-header .right-portion {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-header .right-portion .links-cover {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px;
}

.page-header .right-portion .links-cover li {
  padding: 5px 15px;
}

.page-header .right-portion .links-cover li a {
  cursor: pointer;
  font-weight: 500;
  color: black;
  font-size: 16px;
  text-decoration: none;
}

.page-header .right-portion .links-cover li a:hover {
  color: #221f70;
  /* text-decoration: underline; */
  border-bottom: 2px solid #221f70;
}

.page-header .right-portion .camp-side-cover {
  text-align: left;

  justify-content: flex-start;
}

.page-header .right-portion .camp-side-cover .for-mobile {
  display: none;
}

.page-header .right-portion .camp-side-cover .sidebar-cover {
  background-color: rgba(0, 0, 0, 0.3);
  top: 80px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: calc(100vh - 80px);
  position: fixed;
  display: none;
}
.page-header .right-portion .camp-side-cover .sidebar-cover.active {
  display: block;
}

.page-header .right-portion .camp-side-cover .sidebar-cover .sidebar {
  padding: 20px 0px;
  width: 250px;
  height: 100%;
  margin-left: auto;
  background-color: black;
}

.page-header .right-portion .camp-side-cover .sidebar-cover .sidebar ul {
  width: 100%;
  height: calc(100vh - 83px);
  overflow: auto;
  padding: 0px;
}

.page-header .right-portion .camp-side-cover .sidebar-cover .sidebar ul li {
  padding: 12px 12px 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.page-header .right-portion .camp-side-cover .sidebar-cover .sidebar ul li h4 {
  margin: 0px;
  font-size: 16px;
  color: white;
  font-weight: bold;
}

.page-header .right-portion .camp-side-cover .sidebar-cover .sidebar ul li span {
  color: blue;
  font-size: 26px;
}

.page-header .right-portion .camp-side-cover ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-header .right-portion .camp-side-cover ul li {
  padding: 5px 20px;
  font-size: 14px;
}
.page-header .right-portion .camp-side-cover ul li a {
  color: black;
}

.page-header .right-portion .for-mobile {
  display: none;
}

@media screen and (max-width: 1100px) {
  .page-header .logo-cover {
    width: 140px;
  }
  .page-header .right-portion .camp-side-cover ul li {
    padding: 5px 15px;
  }
  .page-header .right-portion .links-cover li {
    padding: 5px 10px;
  }
  .page-header .right-portion .links-cover li a {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .page-header .right-portion .links-cover {
    display: none;
  }
  .page-header .right-portion .camp-side-cover {
    width: auto;
  }
  .page-header .right-portion .camp-side-cover .for-mobile {
    display: block;
  }
  .page-header .right-portion .camp-side-cover .for-desktop {
    display: none;
  }
  .page-header .right-portion .for-mobile {
    display: block;
  }
  .page-header .right-portion .for-mobile ul {
    display: block !important;
  }
}

@media screen and (max-width: 360px) {
  .page-header .right-portion .camp-side-cover .sidebar-cover .sidebar {
    width: 260px;
  }
}